import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import logo from '../images/logos/logo-black-small.png';

export default function NavBar() {
  return (
    <div>
      <Disclosure as='nav' className='fixed top-0 z-50 mx-auto w-full bg-white'>
        {({ open }) => (
          <>
            <div class='mx-auto w-screen px-4 sm:px-6 lg:px-8 xl:px-12'>
              <div class='flex h-24 justify-between'>
                <div class='-mr-2 flex items-center'>
                  <Disclosure.Button class='inline-flex items-center justify-center p-2 text-brandTextDark hover:bg-white'>
                    <span class='sr-only'>Open main menu</span>
                    {open ? (
                      <XIcon className='block h-10 w-10' aria-hidden='true' />
                    ) : (
                      <MenuIcon
                        className='block h-10 w-10'
                        aria-hidden='true'
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <div class='flex'>
                  <div class='flex flex-shrink-0 items-center'>
                    <a href='#top'>
                      <img
                        class='block h-9 sm:h-12 w-auto pr-5'
                        src={logo}
                        alt='Parking Break Logo'
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel class='flex h-[calc(100vh-64px)] flex-col justify-center'>
              <div class='flex h-full flex-col items-start justify-start space-y-16 px-4 py-16 text-4xl font-extrabold sm:px-6 lg:px-8 xl:px-12'>
                <Disclosure.Button
                  as='a'
                  href='#who-we-are'
                  class='block pl-3 text-brandTextDark duration-300 ease-in-out hover:scale-110'
                >
                  Who We Are
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='#why-parking-break'
                  class='block pl-3 text-brandTextDark duration-300 ease-in-out hover:scale-110'
                >
                  Why Parking Break
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='#our-rates'
                  class='block pl-3 text-brandTextDark duration-300 ease-in-out hover:scale-110'
                >
                  Our Rates
                </Disclosure.Button>
                <Disclosure.Button
                  as='a'
                  href='#contact'
                  class='block pl-3 pb-8 text-brandTextDark duration-300 ease-in-out hover:scale-110'
                >
                  Contact / Locations
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
