import {
  QrcodeIcon,
  LockClosedIcon,
  CurrencyDollarIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/outline';

export default function WhyParkingBreak() {
  const features = [
    {
      name: 'Safety',
      description:
        'Parking Break is committed to providing our customers with a safe and convenient parking experience. Our lots are well lit and feature 24/7 surveillance cameras. And with our automated payments upon entry, you’ll spend less time standing at a payment machine, and more time at your final destination.',
      icon: LockClosedIcon,
    },
    {
      name: 'Convenience',
      description:
        'Parking Break offers reserved parking during business hours. This means no more searching for open spots, long walks to the office, or daily searching for change to pay for parking. Simply pay a flat rate once a month and enjoy the peace of mind of knowing that your parking spot is reserved and waiting for you!',
      icon: OfficeBuildingIcon,
    },
    {
      name: 'Affordability',
      description:
        'Parking Break is committed to providing an affordable alternative to overpriced parking. Whether you are a student, a working professional, a corporation looking to provide parking to your employees, or simply visiting downtown, we are here to help you find the plan that best suits your budget.',
      icon: CurrencyDollarIcon,
    },
    {
      name: 'Technology',
      description:
        'Parking Break is fully-automated, making your morning commute that much easier. Monthly pass holders are given a key card and are granted instant access to the lot upon arrival. With the increase in popularity of electric vehicles, Parking Break is committed to providing our customers with access to remote charging stations. Going to a sporting event? Celebrate the win by returning to a fully-charged vehicle for your drive home.',
      icon: QrcodeIcon,
    },
  ];
  return (
    <section>
      <div id='why-parking-break' class='bg-white py-24 lg:py-96'>
        <div class='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div class='lg:text-center'>
            <h2 class='mt-2 text-5xl font-extrabold tracking-tight text-gray-900 sm:text-7xl'>
              Why Parking Break
            </h2>
          </div>

          <div class='mt-14'>
            <dl class='space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0'>
              {features.map((feature) => (
                <div key={feature.name} className='relative'>
                  <dt>
                    <div className='absolute flex h-16 w-16 items-center justify-center rounded-md bg-white text-brandTextDark'>
                      <feature.icon className='h-6 w-6' aria-hidden='true' />
                    </div>
                    <p class='ml-16 text-3xl font-bold leading-6 text-gray-900'>
                      {feature.name}
                    </p>
                  </dt>
                  <dd class='mt-3 ml-16 text-xl text-gray-900'>
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}
