import heroTest from '../images/guardian.webp';

export default function WhoWeAre() {
  return (
    <section>
      <div
        id='who-we-are'
        class='wwa-padding relative overflow-hidden pt-24 lg:pt-32'
      >
        <div class='mx-auto  px-4  sm:px-6 lg:max-w-7xl lg:px-8'>
          <div>
            <h2 class='mt-2 lg:text-center text-5xl font-extrabold tracking-tight text-gray-900 sm:text-7xl'>
              Who We Are
            </h2>
            <p class='mx-auto mt-8 lg:max-w-prose text-xl text-gray-900 sm:text-2xl'>
              Parking Break is proudly owned and operated in Cleveland, OH. We
              provide safe and convenient parking at an affordable price. In
              addition to our daily parking rate, we offer plans designed for
              businesses as well as individuals. After business hours, event
              parking is available on a first-come, first-served basis.
            </p>
            <p class='mx-auto mt-6 lg:max-w-prose text-xl text-gray-900 sm:text-2xl'>
              As a family-owned business, we are committed to developing a
              personal relationship with each of our clients to help meet their
              specific parking needs.
            </p>
          </div>
          <div class='mt-12 -mb-10 sm:-mb-24 lg:-mb-52'>
            <img
              class='rounded-lg shadow-xl ring-1 ring-black ring-opacity-5'
              src={heroTest}
              alt='Cleveland Guardian on bridge'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
