import { CheckIcon } from '@heroicons/react/outline';

export default function PricingPlans() {
  const hobbyFeatures = ['Safety', 'Convenience', 'Affordability'];
  const scaleFeatures = [
    'Monthly Benefits +',
    'Increased Savings',
    'Corporate Discounts',
  ];
  const growthFeatures = [
    'Discounted Rates',
    'Reserved Parking Spot',
    'Automated Entry with Access Card',
    'No Annual Contracts',
    'Hassle Free Monthly Payments',
  ];
  return (
    <div>
      <div id='our-rates' class='bg-brandBlack'>
        <div class='px-4 pt-24 sm:px-6 lg:px-8 lg:pt-20'>
          <div class='text-center'>
            <h2 class='text-lg font-semibold uppercase leading-6 tracking-wider text-gray-300'>
              our rates
            </h2>
            <p class='mt-2 text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl'>
              Take a Break from Overpriced Parking
            </p>
            <p class='mx-auto mt-3 max-w-4xl text-xl text-gray-300 sm:mt-5 sm:text-2xl'>
              Whether you are an individual looking for affordable parking or a
              corporation wanting to provide parking to your employees, Parking
              Break offers tailored solutions to meet your parking needs.
            </p>
          </div>
        </div>

        <div class='mt-12 bg-white lg:mt-20'>
          <div class='relative z-0'>
            <div class='absolute inset-0 h-5/6 bg-brandBlack lg:h-2/3' />
            <div class='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
              <div class='relative lg:grid lg:grid-cols-7'>
                <div class='mx-auto max-w-md lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3 lg:mx-0 lg:max-w-none'>
                  <div class='flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg'>
                    <div class='flex flex-1 flex-col'>
                      <div class='bg-white px-6 py-10'>
                        <div>
                          <h3
                            class='text-center text-2xl font-medium text-gray-900'
                            id='tier-hobby'
                          >
                            Daily
                          </h3>
                          <div class='mt-4 flex items-center justify-center'>
                            <span class='flex items-start px-3 text-6xl tracking-tight text-gray-900'>
                              <span class='mt-2 mr-2 text-4xl font-medium'>
                                $
                              </span>
                              <span class='font-extrabold'> 5 </span>
                              <span class='text-2xl'>*</span>
                            </span>
                            <span class='text-xl font-medium text-brandTextLight'>
                              /day
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class='flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-50 p-6 sm:p-10 lg:p-6 xl:p-10'>
                        <ul class='space-y-4'>
                          {hobbyFeatures.map((feature) => (
                            <li key={feature} className='flex items-start'>
                              <div className='flex-shrink-0'>
                                <CheckIcon
                                  className='flex-shrink-0 h-6 w-6 text-brandTextDark'
                                  aria-hidden='true'
                                />
                              </div>
                              <p className='ml-3 text-base font-medium text-gray-900'>
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div class='mt-8'>
                          <div class='rounded-lg shadow-md'>
                            <a
                              href='/'
                              class='block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-brandTextDark hover:bg-gray-50'
                              aria-describedby='tier-hobby'
                            >
                              See Locations
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='mx-auto mt-10 max-w-lg lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-0 lg:max-w-none'>
                  <div class='relative z-10 rounded-lg shadow-xl'>
                    <div
                      class='pointer-events-none absolute inset-0 rounded-lg border-2 border-brandBlack'
                      aria-hidden='true'
                    />
                    <div class='absolute inset-x-0 top-0 translate-y-px transform'>
                      <div class='flex -translate-y-1/2 transform justify-center'>
                        <span class='inline-flex rounded-full bg-brandDarkGray px-4 py-1 text-sm font-semibold uppercase tracking-wider text-white'>
                          Most popular
                        </span>
                      </div>
                    </div>
                    <div class='rounded-t-lg bg-white px-6 pt-12 pb-10'>
                      <div>
                        <h3
                          class='text-center text-3xl font-semibold text-gray-900 sm:-mx-6'
                          id='tier-growth'
                        >
                          Monthly
                        </h3>
                        <div class='mt-4 flex items-center justify-center'>
                          <span class='flex items-start px-3 text-6xl tracking-tight text-gray-900 sm:text-6xl'>
                            <span class='mt-2 mr-2 text-4xl font-medium'>
                              {' '}
                              ${' '}
                            </span>
                            <span class='font-extrabold'> 95 </span>
                            <span class='text-2xl'>*</span>
                          </span>
                          <span class='text-2xl font-medium text-brandTextLight'>
                            /month
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class='rounded-b-lg border-t-2 border-gray-100 bg-gray-50 px-6 pt-10 pb-8 sm:px-10 sm:py-10'>
                      <ul class='space-y-4'>
                        {growthFeatures.map((feature) => (
                          <li key={feature} className='flex items-start'>
                            <div className='flex-shrink-0'>
                              <CheckIcon
                                className='flex-shrink-0 h-6 w-6 text-brandTextDark'
                                aria-hidden='true'
                              />
                            </div>
                            <p className='ml-3 text-base font-medium text-gray-900'>
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div class='mt-10'>
                        <div class='rounded-lg shadow-md'>
                          <a
                            href='#contact'
                            class='block w-full rounded-lg border border-transparent bg-brandBlack px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-brandDarkGray'
                            aria-describedby='tier-growth'
                          >
                            Reserve Your Spot
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='mx-auto mt-10 max-w-md lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3 lg:m-0 lg:max-w-none'>
                  <div class='flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-r-lg'>
                    <div class='flex flex-1 flex-col'>
                      <div class='bg-white px-6 py-10'>
                        <div>
                          <h3
                            class='text-center text-2xl font-medium text-gray-900'
                            id='tier-scale'
                          >
                            Yearly
                          </h3>
                          <div class='mt-4 flex items-center justify-center'>
                            <span class='flex items-start px-3 text-6xl tracking-tight text-gray-900'>
                              <span class='mt-2 mr-2 text-4xl font-medium'>
                                $
                              </span>
                              <span class='font-extrabold'> 90 </span>
                              <span class='text-2xl'>*</span>
                            </span>
                            <span class='text-xl font-medium text-brandTextLight'>
                              /month
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class='flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-50 p-6 sm:p-10 lg:p-6 xl:p-10'>
                        <ul class='space-y-4'>
                          {scaleFeatures.map((feature) => (
                            <li key={feature} className='flex items-start'>
                              <div className='flex-shrink-0'>
                                <CheckIcon
                                  className='flex-shrink-0 h-6 w-6 text-brandTextDark'
                                  aria-hidden='true'
                                />
                              </div>
                              <p className='ml-3 text-base font-medium text-gray-900'>
                                {feature}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div class='mt-8'>
                          <div class='rounded-lg shadow-md'>
                            <a
                              href='#contact'
                              class='block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-brandTextDark hover:bg-gray-50'
                              aria-describedby='tier-scale'
                            >
                              Reserve Your Spot
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
