import {
  MailIcon,
  GlobeAltIcon,
  PhoneIcon,
  LocationMarkerIcon,
} from '@heroicons/react/outline';

export default function Contact() {
  return (
    <div>
      <div id='contact' class='bg-white'>
        <div class='mx-auto max-w-7xl px-4 pt-24 sm:px-6 lg:px-8 lg:pt-80'>
          <div class='lg:text-center'>
            <h2 class='mt-2 text-5xl font-extrabold tracking-tight text-gray-900 sm:text-7xl'>
              Contact Us
            </h2>
          </div>
          <div class='mx-auto mt-14 max-w-lg md:grid md:max-w-none md:grid-cols-2 md:gap-8'>
            <div>
              <h2 class='text-3xl font-bold leading-6 text-gray-900'>
                Contact
              </h2>
              <div class='mt-3'>
                <p class='pr-10 text-xl text-gray-900 lg:pr-16'>
                  We'd love to hear from you! Please feel free to call or email
                  us with any questions or inquiries that you may have.
                </p>
              </div>
              <div class='mt-9'>
                <div class='flex'>
                  <div class='flex-shrink-0'>
                    <PhoneIcon
                      class='h-6 w-6 text-gray-900'
                      aria-hidden='true'
                    />
                  </div>
                  <div class='ml-3 text-lg text-gray-900'>
                    <a href='tel:440-541-8499'>(440) 541-8499</a>
                    <p class='mt-1 text-lg'>Mon-Fri 8am to 6pm EST</p>
                  </div>
                </div>
                <div class='mt-6 flex'>
                  <div class='flex-shrink-0'>
                    <MailIcon
                      class='h-6 w-6 text-gray-900'
                      aria-hidden='true'
                    />
                  </div>
                  <div class='ml-3 text-lg text-gray-900'>
                    <a href='mailto: info@parkingbreakllc.com'>
                      info@parkingbreakllc.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class='mt-12 sm:mt-16 md:mt-0'>
              <h2 class='text-3xl font-bold leading-6 text-gray-900'>
                Location
              </h2>
              <div class='mt-3'>
                <p class='pr-10 text-xl text-gray-900'>
                  Located just minutes from downtown attractions and local
                  office buildings, our lot is an excellent starting point for
                  all of your downtown Cleveland adventures.
                </p>
              </div>
              <div class='mt-9'>
                <div class='flex'>
                  <div class='flex-shrink-0'>
                    <LocationMarkerIcon
                      class='h-6 w-6 text-gray-900'
                      aria-hidden='true'
                    />
                  </div>
                  <div class='ml-3 text-lg text-gray-900'>
                    <p>Coming soon</p>
                    <p class='mt-1'>Reserved parking Mon-Fri 8am-5:30pm</p>
                    <p class='mt-1'>Evening event parking</p>
                  </div>
                </div>
                <div class='mt-6 flex'>
                  <div class='flex-shrink-0'>
                    <GlobeAltIcon
                      class='h-6 w-6 text-gray-900'
                      aria-hidden='true'
                    />
                  </div>
                  <div class='ml-3 text-lg text-gray-900 underline'>
                    <p>View location on Google Maps</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class='overflow-hidden bg-white px-4 pt-10 sm:px-6 lg:px-8'>
        <div class='relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
          <div class='mt-12'>
            <form
              action='https://formspree.io/f/xrgjdqlr'
              method='POST'
              class='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
            >
              <div>
                <label
                  for='first-name'
                  class='block text-lg font-bold text-gray-700'
                >
                  First name
                </label>
                <div class='mt-1'>
                  <input
                    type='text'
                    name='first-name'
                    id='first-name'
                    autocomplete='given-name'
                    class='block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-brandBlack focus:ring-brandBlack'
                  />
                </div>
              </div>
              <div>
                <label
                  for='last-name'
                  class='block text-lg font-bold text-gray-700'
                >
                  Last name
                </label>
                <div class='mt-1'>
                  <input
                    type='text'
                    name='last-name'
                    id='last-name'
                    autocomplete='family-name'
                    class='block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-brandBlack focus:ring-brandBlack'
                  />
                </div>
              </div>
              <div class='sm:col-span-2'>
                <label
                  for='company'
                  class='block text-lg font-bold text-gray-700'
                >
                  Company
                </label>
                <div class='mt-1'>
                  <input
                    type='text'
                    name='company'
                    id='company'
                    autocomplete='organization'
                    class='block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-brandBlack focus:ring-brandBlack'
                  />
                </div>
              </div>
              <div class='sm:col-span-2'>
                <label
                  for='email'
                  class='block text-lg font-bold text-gray-700'
                >
                  Email
                </label>
                <div class='mt-1'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    autocomplete='email'
                    class='block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-brandBlack focus:ring-brandBlack'
                  />
                </div>
              </div>
              <div class='sm:col-span-2'>
                <label
                  for='phone-number'
                  class='block text-lg font-bold text-gray-700'
                >
                  Phone Number
                </label>
                <div class='relative mt-1 rounded-md shadow-sm'>
                  <input
                    type='text'
                    name='phone-number'
                    id='phone-number'
                    autocomplete='tel'
                    class='block w-full rounded-md border-gray-300 py-3 px-4 focus:border-brandBlack focus:ring-brandBlack'
                  />
                </div>
              </div>
              <div class='sm:col-span-2'>
                <label
                  for='message'
                  class='block text-lg font-bold text-gray-700'
                >
                  Message
                </label>
                <div class='mt-1'>
                  <textarea
                    id='message'
                    name='message'
                    rows='4'
                    class='block w-full rounded-md border border-gray-300 py-3 px-4 shadow-sm focus:border-brandBlack focus:ring-brandBlack'
                  />
                </div>
              </div>
              <div class='sm:col-span-2'></div>
              <div class='sm:col-span-2'>
                <button
                  type='submit'
                  class='mb-1 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandBlack px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-brandDarkGray focus:outline-none focus:ring-2 focus:ring-brandBlack focus:ring-offset-2'
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
