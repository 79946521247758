import Contact from './components/Contact';
import Footer from './components/Footer';
import Hero from './components/Hero';
import NavBar from './components/NavBar';
import PricingPlans from './components/PricingPlans';
import WhoWeAre from './components/WhoWeAre';
import WhyParkingBreak from './components/WhyParkingBreak';

export default function App() {
  return (
    <div className='text-4xl'>
      {/* <Hero />
      <WhoWeAre />
      <WhyParkingBreak />
      <PricingPlans />
      <Contact /> */}
      <NavBar />
      <Hero />
      <WhoWeAre />
      <WhyParkingBreak />
      <PricingPlans />
      <Contact />
      <Footer />
    </div>
  );
}
