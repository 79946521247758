import logo from '../images/logos/new_logo.png';
import heroImage from '../images/hero.webp';

export default function Hero() {
  return (
    <section>
      <div
        id='top'
        class='mt-16 flex flex-col justify-center lg:min-h-[calc(100vh-64px)]'
      >
        <div class='relative bg-white'>
          <div class='lg:relative'>
            <div class='mx-auto py-12 w-full max-w-7xl text-center lg:text-left'>
              <div class='px-4 sm:px-8 lg:w-1/2 xl:pr-16'>
                <div class='flex justify-center'>
                  <img
                    class='px-12 pt-6 sm:px-24 md:px-32 lg:px-0 lg:pt-0'
                    id='hero-image'
                    src={logo}
                    alt='Parking Break logo'
                  />
                </div>
                <div class='mt-10 justify-center sm:flex'>
                  <div class='rounded-md shadow'>
                    <a
                      href='#our-rates'
                      class='flex w-full items-center justify-center rounded-md border border-transparent bg-brandBlack px-12 py-3 text-lg font-bold text-white hover:bg-brandDarkGray md:py-4 md:px-16'
                    >
                      Rates
                    </a>
                  </div>
                  <div class='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                    <a
                      href='#contact'
                      class='flex w-full items-center justify-center rounded-md border border-transparent bg-white px-12 py-3 text-lg font-bold text-brandTextDark hover:bg-gray-50 md:py-4 md:px-16'
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class='relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2'>
              <img
                class='absolute inset-0 h-full w-full object-cover'
                src={heroImage}
                alt='Rock and Roll Hall of Fame'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
